<template>
    <b-container fluid class="blockFq2">
        <b-container>
            <b-card class="blockFq2-card text-left">
                             <img src="../img/blockGq2-1.svg" class="blockGq2-1" alt="">
                              <img src="../img/blockGq2-2.svg" class="blockGq2-2" alt="">
                             <img src="../img/blockGq2-3.svg" class="blockGq2-3" alt="">
                              <img src="../img/blockGq2-4.svg" class="blockGq2-4" alt="">
                <b-form @submit.prevent="sendData">
                    <b-row >
                        <b-col lg="6">
                            <b-row>
                                <b-col lg="6" class="blockGq2-pr">
                                    <p class="blockFq2-p">First Name</p>
                                    <b-form-group>
                                        <b-form-input class="blockS2-inp " v-model="first_name" placeholder="Enter first name"></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col lg="6" class="blockGq2-pl">
                                    <p class="blockFq2-p">Last Name</p>
                                    <b-form-group>
                                        <b-form-input class="blockS2-inp " v-model="last_name" placeholder="Enter last name"></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col lg="6">
                               <p class="blockFq2-p">Phone</p>
                                    <b-form-group>
                                        <b-form-input class="blockS2-inp " v-model="phone" placeholder="Enter conact phone number"></b-form-input>
                                    </b-form-group>
                            </b-col>
                    </b-row>
                    <b-row>
                        <b-col lg="6">
                             <p class="blockFq2-p">Company Name</p>
                                    <b-form-group>
                                        <b-form-input class="blockS2-inp " v-model="company_name" placeholder="Enter company name"></b-form-input>
                                    </b-form-group>
                        </b-col>
                        <b-col lg="6">
                             <p class="blockFq2-p">E-mail</p>
                                    <b-form-group>
                                        <b-form-input class="blockS2-inp " v-model="email" placeholder="Enter conact e-mail adress"></b-form-input>
                                    </b-form-group>
                        </b-col>
                    </b-row>
                     <b-row>
                        <b-col lg="6">
                             <p class="blockFq2-p">Truckload Type</p>
                                    <b-form-group>
                                         <v-select
                                                class="select__dashboard-base"
                                                v-model="truck_load"
                                                :options="options_truck_load"
                                            ></v-select>
                                    </b-form-group>
                        </b-col>
                        <b-col lg="6">
                             <p class="blockFq2-p">Truckload Type Needed</p>
                                    <b-form-group>
                                        
                                         <v-select
                                                class="select__dashboard-base"
                                                v-model="truck_load_needed"
                                                :options="options_truck_load_needed"
                                            ></v-select>
                                   
                                    </b-form-group>
                        </b-col>
                    </b-row>
                     <b-row>
                        <b-col lg="6">
                             <p class="blockFq2-p">Shipping from</p>
                                    <b-form-group>
                                        <b-form-input class="blockS2-inp " v-model="zip_from" placeholder="Enter ZIP code"></b-form-input>
                                    </b-form-group>
                        </b-col>
                        <b-col lg="6">
                              <p class="blockFq2-p">Shipping to</p>
                                    <b-form-group>
                                        <b-form-input class="blockS2-inp " v-model="zip_to"  placeholder="Enter ZIP code"></b-form-input>
                                    </b-form-group>
                        </b-col>
                    </b-row>
                     <b-row>
                        <b-col lg="6">
                             <p class="blockFq2-p">Comment</p>
                                    <b-form-group>
                                        <b-form-textarea class="blockS2-inp " style="height:120px" v-model="comment" no-resize placeholder="Comment"></b-form-textarea>
                                    </b-form-group>
                        </b-col>
                        <b-col lg="6">
                              <b-row>
                                <b-col lg="6" class="blockGq2-pr">
                                    <p class="blockFq2-p">Pickup Date</p>
                                    <b-form-group>
                                        <b-form-datepicker class="blockS2-inp " :locale="locale" style="height:50px" v-model="pickup_date"  placeholder="Select date"></b-form-datepicker>
                                    </b-form-group>
                                </b-col>
                                <b-col lg="6" class="blockGq2-pl">
                                    <p class="blockFq2-p">Delivery Date</p>
                                    <b-form-group>
                                        <b-form-datepicker class="blockS2-inp " :locale="locale" style="height:50px" v-model="deliver_date" placeholder="Select date"></b-form-datepicker>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-button class="btn-primery" style="width:100%" type="submit">Request a quote</b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </b-card>
        </b-container>
    </b-container>
</template>

<script>
import {mapGetters} from 'vuex'
import { httpClient } from '../HttpClientV1/http-client'
export default {
    data(){
        return{
            locale:'en-US',
            zip_from: null,
            zip_to: null,
            first_name: null,
            last_name: null,
            email:null,
            phone: null,
            company_name: null,
            truck_load: null,
            truck_load_needed: null,
            comment: null,
            pickup_date: null,
            deliver_date: null,
            options_truck_load: [
                {code:"Full Truckload", label: "Full Truckload"},
                {code:"Less Than Truckload (LTL)", label: "Less Than Truckload (LTL)"},
            ],
            options_truck_load_needed: [
                {code:"Dry Van", label: "Dry Van"},
                {code:"Flat Bed", label: "Flat Bed"},
                {code:"Refrigerated", label: "Refrigerated"},
                {code:"Intermodal", label: "Intermodal"},
                {code:"Expedited", label: "Expedited"},
                {code:"Drayage", label: "Drayage"},
            ]
        }
    },
    methods:{
       ...mapGetters(['getZip']),
       getData(){
           var data = this.getZip()
           console.log(data)
           this.zip_from = data.zip_from,
           this.zip_to = data.zip_to
       },
       sendData(){
           var data = {
               quote_request:{
                    first_name: this.first_name,
                    last_name: this.last_name,
                    email:this.email,
                    phone: this.phone,  
                    zip_from: this.zip_from,
                    zip_to: this.zip_to,                            
                    company_name: this.company_name,
                    truck_load: this.truck_load.label,
                    truck_load_needed: this.truck_load_needed.label,
                    comment: this.comment,
                    pickup_date: this.pickup_date,
                    deliver_date: this.deliver_date,
               }
           }
           httpClient
           .post('api/quote_request',  data)
           .then(()=>{
               this.first_name = this.last_name = this.zip_to = this.zip_from = this.email = this.phone = this.company_name = this.truck_load = this.truck_load_needed = this.comment = this.pickup_date = this.deliver_date = null
                 this.$router.push('/thank-you-quote')
           })
       }
    },
    mounted(){
        this.getData()
    }
}
</script>