<template>
  <div style="overflow:hidden">
    <TopNavBar></TopNavBar>
    <TopNavBar2 id="navbar2" class="tnb2none"></TopNavBar2>
    <blockGq1></blockGq1>
    <blockGq2></blockGq2>
    <form1></form1>
    <Footer1></Footer1>
  </div>
</template>

<script>
import TopNavBar from '../components/TopNavBar.vue'
import TopNavBar2 from '../components/TopNavBar2.vue'
import form1 from '../components/form.vue'
import blockGq1 from '../components/blockGq1.vue'
import blockGq2 from '../components/blockGq2.vue'
import Footer1 from '../components/footer.vue'
export default {
  metaInfo() {
    return {
      title: this.MetaData['title'], // set a title
      titleTemplate: this.MetaData['titleTemplate'], // %s  required
      htmlAttrs: this.MetaData['htmlAttrs'],
      meta: this.MetaData['meta'],
      link: [
        { rel: 'canonical', href: 'https://pennlogisticsllc.com/get-quote' }
      ]
    }
  },
  data() {
    return {
      metaData: {

        en: {
          Homepage: {
            title: 'Get FTL & LTL  Freight Shipping Quote | Pennsylvania Logistics', // set a title
            titleTemplate: '', // %s  required
            htmlAttrs: {
              lang: "en",
              amp: undefined // "amp" has no value
            },
            meta: [
              {
                'name': 'keywords',
                'content': 'freight transportation services, ftl shipping, ltl shipping, trucking, truckload, transportation company',
              },
              {
                'name': 'description',
                'content': 'Experience great pricing, flexible approach & amazing service from Pennsylvania Logistics team. Freight delivery is always on time. 24/7 customer support. ',
              },
              {
                property: 'og:url',
                content: 'https://pennlogisticsllc.com/get-quote',
                 vmid: 'og:url'
              },
              {
                property: 'og:title',
                content: 'Get FTL & LTL  Freight Shipping Quote | Pennsylvania Logistics',
                vmid: 'og:title'
              },
              {
                property: 'og:description',
                content: 'Experience great pricing, flexible approach & amazing service from Pennsylvania Logistics team. Freight delivery is always on time. 24/7 customer support.',
                vmid: 'og:description'
              },
              {
                property: 'og:type',
                content: 'website',
                vmid: 'og:type'
              },
              {
                property: 'og:image',
                content: '../img/blockGq1.webp',
                vmid: 'og:image'
              },

            ],
            link: [
              { rel: 'canonical', href: 'https://pennlogisticsllc.com/get-quote' }
            ]
          },
        },
      },
    }
  },
  name: 'Home',
  components: {
    TopNavBar, form1, Footer1, blockGq1, blockGq2, TopNavBar2
  },
  computed: {
    MetaData() {
      return this.metaData['en']['Homepage']
    }
  },
  mounted() {
    this.anim()
  }
}
</script>
